// Application Configuration
window.APP_CONFIG = {
    // WebSocket Configuration
    WS_ENDPOINT: window.location.protocol === 'https:' 
        ? 'wss://' + window.location.host + '/ws'
        : 'ws://' + window.location.host + '/ws',
    WS_RECONNECT_ATTEMPTS: 5,
    WS_RECONNECT_DELAY: 1000,
    WS_PATH: '/ws',

    // AWS Configuration
    COGNITO_USER_POOL_ID: 'us-west-2_elgiPTht4',
    COGNITO_CLIENT_ID: '4lp68ka85qlei2d5hv190pdojm',
    AWS_REGION: 'us-west-2',

    // Environment
    ENV: window.location.hostname.includes('dev') ? 'development' : 'production',
    DEBUG: window.location.hostname.includes('dev'),

    // API Configuration
    API_BASE_URL: window.location.origin,
    API_VERSION: 'v1',

    // Feature Flags
    FEATURES: {
        WEBSOCKET_ENABLED: true,
        METRICS_ENABLED: true
    }
}; 